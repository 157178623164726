<template>
  <div>
    <table-page dataName="lists"
                ref="table"
                @on-success="fetchSuccess"
                :params="{activity_id:$route.query.id}"
                :request="extractLogs">
      <template slot="button">
        <el-button type="primary"
                   v-permission="['ADMIN']"
                   class="mr-15"
                   @click="statistics">统计</el-button>
        <FileExport :params="exportParams">导出领取记录</FileExport>
      </template>

      <el-table-column prop="tel"
                       show-overflow-tooltip
                       label="手机号码">
      </el-table-column>
      <el-table-column prop="id_code"
                       show-overflow-tooltip
                       label="身份证号">
      </el-table-column>
      <el-table-column prop="openid"
                       show-overflow-tooltip
                       label="OPENID">
      </el-table-column>
      <el-table-column prop="appointment_at"
                       show-overflow-tooltip
                       label="预约提交时间">
      </el-table-column>
      <el-table-column prop="money"
                       show-overflow-tooltip
                       label="红包金额">
        <template slot-scope="scope">
          {{scope.row.money / 100 + "元"}}
        </template>
      </el-table-column>
      <el-table-column prop="receive_at"
                       show-overflow-tooltip
                       label="领取时间">
      </el-table-column>
      <el-table-column prop="status"
                       show-overflow-tooltip
                       label="领取状态">
      </el-table-column>
      <el-table-column prop="res_status"
                       show-overflow-tooltip
                       label="反馈状态">
      </el-table-column>
      <el-table-column prop="marketer_name"
                       show-overflow-tooltip
                       label="关联员工">
      </el-table-column>
      <el-table-column prop="marketer_net"
                       show-overflow-tooltip
                       label="员工所属网点">
      </el-table-column>
      <el-table-column prop="org_id"
                       show-overflow-tooltip
                       label="机构号">
      </el-table-column>
    </table-page>
    <el-dialog title="提示"
               :visible.sync="dialogVisible"
               width="50%">
      <div v-if="resData"
           class="el-dialog-container">
        <div class="el-dialog-h1">红包</div>
        <div class="el-dialog-h2">已领取红包统计</div>
        <div>已领取金额：{{resData.allMoney}}元； 已领取数：{{resData.allRecive}}个；</div>
        <div class="el-dialog-h1">明细</div>
        <div v-if="resData.data && resData.data.length>0">
          <div v-for="(item,index) in resData.data"
               :key="index">
            <div>{{item}}</div>
          </div>
        </div>
        <div v-else>
          本次活动暂无现金红包奖品
        </div>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import TablePage from '@/components/TablePage'
import FileExport from '@/components/FileExport'
import { extractLogs, extractStatistics } from '@/api/market'
export default {
  data () {
    return {
      exportParams: null,
      extractLogs,
      dialogVisible: false,
      paramsObj: {
      },
      exportUrl: '',
      resData: null
    }
  },
  components: {
    TablePage,
    FileExport
  },
  methods: {
    async statistics () {
      const res = await extractStatistics({
        activity_id: this.$route.query.id
      })
      if (res.meta.code === 0) {
        this.resData = res.data
        this.dialogVisible = true
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    onSearch (e) {
      this.searchParams = e
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    },
    showTable (id) {
      this.dialogVisible = true
      this.paramsObj = {
        activity_id: id
      }
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    },
    fetchSuccess (e) {
      this.exportParams = e.export
    },
    exportData () {
      window.open(this.exportUrl, '_blank')
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
.el-dialog-container {
  div {
    margin-top: 10px;
  }
  .el-dialog-h1 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: bold;
    color: #333;
  }
  .el-dialog-h2 {
    font-size: 14px;
    font-weight: bolder;
    color: #494949;
  }
}
</style>
